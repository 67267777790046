const shop = {
    title: 'ユーザー一覧',
    sauna: 'sauna',
    water: 'water',
    mail_to:
        'mailto:{{shop_mail}}?subject=saunaXX{{name}}店について&cc={{mail_cc}}',
    number: `<div class="span-number">{{0}}</div>
            <div>名</div>
            <div class="span-line">/</div>
            <div class="span-number">{{1}}</div>
            <div>名</div>`,
    shopDetail: {
        point_time: 'stone / <span>{{time}}</span>分',
        having_point_title: '残高',
        btn_buy_point: 'ストーン購入',
        btn_policy: '利用規約',
        btn_qrcodes: {
            1: '利用規約に同意して、入場する',
            2: '営業時間外',
            3: '満員のため入場できません',
        },
        note: '入場から30分が経過すると、30分ごとに{{point}}stoneが必要です。',
        confirm_price: '下部ボタンを押した時点で料金確定となります。',
    },
    cardIntro: {
        desc: '',
    },
    entry: {
        btn_entry: '入場後、画面が切り替わらない場合はこちら',
        title: 'QRコードをリーダーにかざして入場してください。',
        time: {
            left: '＜ 有効期限 ｜ 残り',
            right: '＞',
        },
        point: {
            stone_real: 'stone / {{0}}分延長',
        },
        note: 'QRコードをリーダーにかざした時刻が、入場時刻となります。\n退場の際もQRコードをリーダーにかざしてください。\n入場から退場までが自動計算され、所有ストーンから清算されます。',
    },
    entryInfo: {
        note: 'お帰りの際はQRコードをリーダーにかざして退場してください。',
        admission_time: '入場時刻',
        elapsed_time: '経過時間',
    },
    confirm: {
        title_modal: 'ボーナス チャンス！',
        btn: {
            ok: 'ボーナスを獲得する',
            cancel: '断る',
        },
        text: {
            up: '延長{{0}}分が無料になる',
            down: '「エチケットボーナス」を獲得しよう！',
        },
        desc: '上記のエチケットにご協力いただくと、{{0}}分延長が無料になります。',
    },
    implement: {
        title_modal: 'エチケットボーナス挑戦中！',
        btn: {
            ok: '確定',
            cancel: 'やめる',
        },
        btn_accept: '完了',
        text: {
            up: '達成した項目にチェックをして完了すると、',
            down: '「延長{{0}}分無料」のエチケットボーナスが貰えます！',
        },
        desc: '上記のエチケットにご協力いただくと、{{0}}分延長が無料になります。',
    },
    checkPoint: {
        title: '退店する',
    },
    notEnoughMoney: {
        title_modal: 'ストーン残高不足',
        btn: 'ストーンを購入する',
        text: '<span style="font-family:Century !important">{{0}}</span>stone不足しています。\nストーンを購入してください。',
    },
    complete: {
        title_modal: 'XX BONUS',
        text_number: '分延長 \n無料',
        text: {
            up: '延長{{0}}分が無料になる',
            down: '「エチケットボーナス」を獲得しました！',
        },
    },
    lostItem: {
        title: '入場の際はQRコードをリーダーにかざしてください。',
        time: {
            left: '＜ 有効期限 ｜ 残り',
            right: ' ＞',
        },
        desc: '{{0}}分間の再入場が可能です。',
        note: '入場から{{0}}分が経過すると、{{1}}分ごとに{{2}}stoneが必要です。',
    },
    exit: {
        btn_exit: '退場後、画面が切り替わらない場合はこちら',
        title: '退場の際はQRコードをリーダーにかざしてください。',
        title_modal: 'ストーン不足',
        note: '次のQRコードをかざすことで、退場となります。',
    },
    qrcode: {
        start_time: '入場時刻',
        current_time: '現在時刻',
        count_time: '滞在時間',
        point: '利用料金',
        btn: '退場する',
        note: '入場から{{0}}分が経過すると、{{1}}分ごとに{{2}}stoneが必要です。',
    },
    policy: `SAUNA XX（以下「当施設」といいます。）のご利用につきまして、より安全に快適にご利用頂くため次の利用規約（以下「本規約」といいます。）を定めております。当施設をご利用の場合、必ず内容をご確認いただき、本規約の内容に同意いただいた上でのご利用をお願いいたします。当施設は、お客様が本規約の全ての内容に同意いただき、 これを遵守していただくことでご利用いただけるものとします。

    〈ご利用いただけない方〉
    ・飲酒をされている方、酒気を帯びている方
    ・医師から入浴を禁じられている方
    ・威圧的な態度等で他のお客様が怯えると当社が認めた方
    ・和彫りの刺青のある方
    ・当社が不適当と認めた方
    ・体調が優れない方
    ・以下に該当する健康状態の方
    禁忌症急性疾患、活動性の結核、悪性腫瘍、心臓病、呼吸不全、髄不全、腎不全、出血性疾患、皮膚疾患、高血圧症、その他の一般に病勢進行中の疾患に罹患している方、貧血状態であるお客様、伝染病および感染症に罹患している恐れのある方、下痢症状のある方、手術後すぐや極度の疲労状態の方、出血を伴う怪我がある方等

    当施設へのご入場をもって、上記状態のいずれにも該当しないことを表明されたものとします。また、上記状態に伴い生じた体調不良や損害や被害について、当社は何ら責任を負うものではないことをご確認いただいたものとします。

    〈ストーン購入〉
    LINE連携アプリ（以下「アプリ」といいます。）よりストーン（当施設のみで利用できるポイント）をクレジットカード決済にて購入します。入場に必要なストーンは、入場時点の施設内滞在者数によって変わります。所有ストーンはアプリ内のウォレットで確認することができます。ストーンの有効期間は購入から6か月間となります。購入したストーンは、理由の如何を問わず返金を求めることはできません。

    〈入退場方法〉
    入場時は、アプリに入場に必要なストーンとQRコードが表示されます。QRコードを読み取り機にかざすことで入場となります。退場時は、アプリに退場に必要なストーンとQRコードが表示されます。QRコードを読み取り機にかざすことで退場となります。アプリがQRコードを読み取った時刻が入退場の時刻となります。退場処理を行わずに退場された場合、2時間延長したものとみなし2時間延長分のストーンを自動精算します。
    ​
    〈禁止事項〉
    ・サウナ室への可燃物(新聞・雑誌・タバコ等)やIT機器(PC、携帯電話、スマートフォン、タブレット等)の持ち込み
    ・サウナ室での排泄行為、髪染め、アカスリ、洗濯等、サウナ利用目的以外のご利用
    ・喫煙（全館禁煙）
    ・従業員や他のお客様にカメラやスマートフォンを向ける行為
    ・当施設内の備品の持ち出し
    ・シューズロッカーおよびロッカーの複数利用
    ・2枚を超えるタオルのご利用（タオルはお一人様2枚までご利用可能です）
    ・5分未満の間隔でのロウリュウ、1度に2杯以上のロウリュウ（ロウリュウは5分をあけて1杯まで）
    ・サウナマットの複数利用
    ・当施設内での大声でのおしゃべり、他のお客様が不快と思う行為、または威圧行為
    ・過度な頻度でローリュウをかける行為
    ・当施設の設備または備品に損害を与える行為

    〈忘れ物〉
    当施設における忘れ物について、お客様は残置から７日経過時に一切の権利を放棄したものとし、当施設にて処分することに異議を述べないものとします。ただし、腐敗等安全衛生上の問題を生じるおそれがある場合、当施設は期間の経過前であっても処分を行うことがあります。

    〈免責〉
    当施設ご利用中の傷病、事故、盗難、紛失につきましてはお客様ご自身で充分にご注意いただきますようお願いいたします。当施設のご利用に関してお客様に何らかの損害が生じた場合であっても、当施設は一切の責任を負いかねます。

    〈利用制限〉
    以下の場合、当施設の利用を制限することがございます。
    ・気象、災害、その他やむを得ない理由等により当施設が営業を行うことが妥当でないと認めたとき
    ・警報、注意報などにより当施設が営業を行うことが妥当でないと認めたとき
    ・施設の点検、補修または改修をするとき
    ・法令の制定、改廃、行政指導、社会経済情勢の著しい変化、その他やむを得ない理由が発生したとき
    ・定休日、年末年始、その他都合により当施設が休業を必要と認めるとき

    〈反社会的勢力の制限〉
    当施設は、以下に該当する者のご利用を固くお断りします。万が一事後発覚した場合、必要に応じて警察等の関連機関に通報、連絡等の上で速やかにご退店いただき、今後のご利用も禁止いたします。なお、ご利用料金のご返金はいたしかねます。
    ・暴力団、暴力団員、暴力団準構成員、暴力団関連企業、総会屋等、社会運動等標ぼうゴロ、特殊知能暴力集団その他これらに準ずる者（以下「反社会的勢力」といいます。）
    ・過去5 年間において、反社会的勢力に該当したことがある者
    ・反社会的勢力もしくは反社会的勢力と密接な関係がある者によって経営を支配もしくは関与されている者
    ・自らが反社会的勢力を利用、もしくは資金または便宜等を提供している者
    ・反社会的勢力と社会的に非難されるべき関係等を有する者

    〈規約の改正〉
    当施設の裁量により本規約の改正を行うことができ、改正した本規約の効力は全てのお客様に及ぶものとします。本規約におけるお客様への告知方法は当施設ウェブサイトに掲載する方法とします。 
    ​`,
    btn_reentry: '退場用QRコードを再表示する',
    btn_re_exit: '入場用QRコードを再表示する',
    re_exit: {
        title: '退場の際はQRコードをリーダーにかざしてください。',
    },
}
export default shop
